
















import Vue from "vue";
import OnboardingHeader from "@/components/shared/onboarding/OnboardingHeader.vue";
import OnboardingFooter from "@/components/shared/onboarding/OnboardingFooter.vue";
import OnboardingDecoration from "@/components/shared/onboarding/OnboardingDecoration.vue";
export default Vue.extend({
  name: "AuthenticationIndex",
  components: {
    OnboardingDecoration,
    OnboardingHeader,
    OnboardingFooter
  }
});
