
























































import Vue from "vue";
import { IApp } from "@/interfaces";
import { clean_string } from "@/utils/global";
export default Vue.extend({
  name: "AuthBoardingHeader",
  data() {
    return {
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      language_options: [
        {
          key: "EN",
          value: "en"
        }
      ],
      current_language: localStorage.getItem("locale") || "en"
    };
  },
  computed: {
    AppPortal(): IApp.Portals {
      return process.env.VUE_APP_PORTAL || IApp.Portals.HCMS;
    },
    Portals() {
      return IApp.Portals;
    }
  },
  methods: {
    clean_string
  }
});
